@import url('https://fonts.googleapis.com/css2?family=Telex&display=swap');

body {
  background: #212121;
  color: white;
}

.form-container {
    border-top: none;
    font-family: "Telex", sans-serif;
    width: 100%;
    justify-items: center;
    
  }

h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
.form-group {
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin: 0 0 0 5%;
  width: 90%;
  height: 40px;
  margin-bottom: 15px;
  background: rgba(206, 205, 205, 0.678);
    /* background:  var(--tg-theme-button-text-color); */
  }
.form-group input {
  width: 80%;
  text-align: center;
}

.form-group label {
  margin: 0 0 0 20px;
}
  
input {
  height: 100%;
  padding: 0 5px 0 5px;
  line-height: 40px;  
    background: none;
    border: none;
    color: rgb(255, 255, 255);
    outline: none;
    font-size: 14px;

}

option {
  background-color: rgba(206, 205, 205, 0.678);
  color: black;
}



select {
  color: rgb(255, 255, 255);
  font-size: 16px;
  text-align: center;
  width: 50%;
  border: 0;
  background-color: rgba(206, 205, 205, 0.678);
  height: 100%;
  margin-left: 20%;
  
}

select {
  background:none; /* Цвет фона */
  color: rgb(255, 255, 255);
  padding: 10px; /* Внутренний отступ */
  border-radius: 5px; /* Скругление углов */
  font-size: 16px; /* Размер шрифта */
}



input::placeholder{
  color: rgb(230, 224, 224);
}

input:focus{
  box-shadow: none;
  
}

label {
    margin-bottom: 5px;
    margin: 0 10px 0 10px;
  }

.form-time {
  display: flex;
  align-items: center;
  margin: 0 0 0 5%;
  border-radius: 10px;
  width: 90%;
  height: 40px;
  margin-bottom: 15px;
  background: rgba(206, 205, 205, 0.678);
}

#start-time {
  width: 30%;
}

#end-time {
  width: 30%;
}

.form-fi {

  border-radius: 10px;
  display: flex;
  align-items: center;
  margin: 0 0 0 5%;
  width: 90%;
  height: 40px;
  margin-bottom: 15px;
  background: rgba(206, 205, 205, 0.678);
}

.form-fi input, .form-time input {
  display: inline-block;
  width: 30%;
}
  
.form-fi label, .form-time label {
  width: 20%;
  display: inline-block;
  margin-left: 20px;

}

.date-input{
    width: 50%;
    padding: 10px;
    border: none;
    margin-left: 20px;
  }



.time-input {
  display: inline-block;
  margin-right: 5px;
  border: none;
}
  

