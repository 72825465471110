@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');


* {
    margin: 0;
    padding: 0;
}

body {
    background-color: #333;
}




.user {
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    background-color: #CAF0F8;
    /* opacity: 10; */
    margin: 0 0 5px 0;
    border-radius: 5px;
    overflow: hidden;
}

.bottom,
.top {
    width: 100%;
}

.ac_name {
    font-weight: 500;
    width: 30%;
    float: right;
    margin-bottom: 2px;
    text-align: right;
    padding-right: 2%;
}

.name {
    padding-left: 2%;
    font-weight: 800;
    width: 66%;
    float: left;
    margin-bottom: 2px;
}

.text {
    width: 85%;
    padding-left: 2%;
    font-weight: 300;
    text-shadow: black 10px;
    float: left;
}

.check {
    transition: all .5s;
    height: 100%;
    width: 3%;
    float:  right;
    margin-right: 1%;
}

.check:hover {
    transition: all .5s;
    transform: scale(1.5);
}

.desc {
    transition: all .5s;
    margin-left: 10px;
    height: 100%;
}

.desc:hover {
    transition: all .5s;
    transform: scale(1.3);
}

.block {
    transition: all .5s;
    margin-left: 5px;
}

.block:hover {
    transition: all .5s;
    transform: scale(1.3);
}

.loc, .vac {
    font-size: 16px;
    font-weight: 300;
    margin-left: 2%;
}

.header {
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.623);
    margin-bottom: 10px;
    border-radius: 0 0 10px 10px;
}

.chat {
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
}

.header-left {
    float: left;
    width: 60%;
}

.header-rigt {
    float: right;
}

.header p b {
    margin-left: 10px;
    margin-right: 20px;
}

select {
    background: #9e9a9a;
    border-radius: 5px;
}

.count {
    background-color: red;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 300;
}

.link {
    color: black
}

.chat {
    width: 100%;
    position: absolute;
    margin-top: 35px;
    padding-bottom: 50px;
    
}

.container {
    width: 100%;
    height: 100%;
}


.my-msg {
    border-radius: 15px 15px 0 15px;
    margin: 0 10px 5px 0;
    width: 80%;
    float: right;
    background: white;
    text-align: justify;
}

.client-msg, .system-msg{
    text-align: justify;
    border-radius: 15px 15px 15px 0;
    margin: 0 10px 5px 0;
    width: 80%;
    float: left;
    background: white;
}
.circle {
    background-color: #7abbf8;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    float: right;
    margin-right: 20px;
}
.chat-top {
    width: 100%;
}

.my-msg .date {
    float: left;
    font-weight: 300;
    font-size: 12px;
    margin-left: 3%;
}

.client-msg .date,
.system-msg .date {
    margin-right: 3%;
    font-size: 12px;
    font-weight: 300;
    float: right;
}

.client-msg .name,
.system-msg .name {
    margin-left: 3%;
    width: 97%;
    float: right;
}

.my-msg .name {
    margin-right: 3%;
    width: 97%;
    text-align: right;
    float: right;
}

.chat-text {
    word-wrap: break-word;
    width: 90%;
    text-align: center;
    margin: 3px 5% 3px 5%;
    
}

.chat-head {
    width: 100%;
    z-index: 10;
    border-radius: 0 0 10px 10px;
    top: 0;
    position: fixed;
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    height: 30px;
    background-color: #CAF0F8;
}

.chat-head p {
    font-size: 20px;
    width: 40%;
    height: 100%;
    margin-right: 30%;
    float: right;
    font-weight: 800;
}

.chat-block {
    height: 100%;
    width: 5%;    
}
.chat-back {
    height: 100%;
    width: 10%;
    float: left;
}



.chat-input {
    z-index: 9999;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #CAF0F8;
    height: 50px;
    position: fixed;
    width: 100%;
    bottom: 0;
}
textarea {
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    text-align: center;
    padding: 5px;
    float: left;
    width: 80%;
    height: 70%;
    margin-right: 5%;
    opacity: 100;
}

.chat-send {
    float: right;
    height: 100%;
    width: 5%;
    margin-right: 3%;
}